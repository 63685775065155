import styled, { css } from 'styled-components';
import { colorUsage, font, getSpacing, onLargeScreen, onSmallScreen } from '@stylesheet';
import theme from 'styled-theming';
import LikeButton from '@components/LikeButton';
import { textAnimationIn, textAnimationOut } from '@utils/animation';

export const Container = styled.div`
  padding: ${getSpacing(2)} ${getSpacing(1)};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  /** Workaround for borders to prevent them from doubling */
  ${onSmallScreen(css`
    box-shadow: 0 0 0 1px ${colorUsage.black};
  `)}
  ${onLargeScreen(css`
    outline: 1px solid ${colorUsage.black};
    margin-top: 1px;
    margin-right: 1px;
    aspect-ratio: 1;
    &:hover {
      .product-img {
        transition: transform 0.4s;
        -ms-transform: scale(0.8);
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
      }
      div, .product-title {
        animation: ${textAnimationIn(colorUsage.white)} 0.3s linear 1 forwards;
      }
      .product-lookbook-numeric-order{
        animation: none;
      }
    }
  `)}
`;

export const Image = styled.img`
  height: ${getSpacing(15)};
  width: ${getSpacing(15)};
  justify-content: center;
  align-content: center;
  align-items: center;
  ${onLargeScreen(css`
    width: 70%;
    height: auto;
    transition: transform 0.4s;
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  `)}
`;

export const MainImage = styled(Image)`
  ${onSmallScreen(css`
    height: ${getSpacing(30)};
    width: ${getSpacing(30)};
  `)}
  ${onLargeScreen(css`
    width: 70%;
    height: auto;
    transition: transform 0.4s;
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  `)}
`;

export const NameContainer = styled.div`
  ${onLargeScreen(css`
    width: 100%;
    height: 100px;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid ${colorUsage.black};
    position: absolute;
    animation: ${textAnimationOut} 0.4s linear 1 forwards;
  `)}
`;

export const Name = styled.p`
  ${font.medium};
  color: ${colorUsage.black};
  text-align: center;

  ${onLargeScreen(css`
    opacity: 0;
    animation: ${textAnimationOut} 0.4s linear 1 forwards;
  `)}

  ${onSmallScreen(css`
    line-height: 20px;
    margin: 10px;
  `)}

  ${theme('language', {
    ar: css`
      text-align: right;
      direction: rtl;
    `,
  })}
`;

export const Favorite = styled(LikeButton)`
  position: absolute;
  top: ${getSpacing(2)};
  right: ${getSpacing(2)};
  ${onLargeScreen(css`
    top: ${getSpacing(3)};
    right: ${getSpacing(3)};
  `)}
`;

export const LookbookNumericOrder = styled.div`
  background-color: ${colorUsage.burgundy};
  width: ${getSpacing(3)};
  height: ${getSpacing(3)};
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: ${getSpacing(2)};
  left: ${getSpacing(2)};
  ${onLargeScreen(css`
    width: ${getSpacing(5)};
    height: ${getSpacing(5)};
    top: ${getSpacing(3)};
    left: ${getSpacing(3)};
  `)}
`;

export const LookbookNumericOrderText = styled.p`
  ${font.Small};
  line-height: 1px;
  color: ${colorUsage.white};
  ${onLargeScreen(css`
    margin-top: 2px;
    ${font.Large};
  `)}
`;
