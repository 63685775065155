import styled, { css } from 'styled-components';
import { onLargeScreen, onSmallScreen, colorUsage, getSpacing, font, fontWeight } from '@stylesheet';

export const Background = styled.div`
  margin-top: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 100%;
  background-color: ${colorUsage.white};
  
  ${onSmallScreen(css`
    margin-top: 50px;
  `)}

  &.product-list-enter {
    transform: translateY(0);
  }

  &.product-list-enter-active {
    transform: translateY(-100%);
    transition: transform 600ms;
  }
`;

export const Container = styled.div`
  height: auto;
  width: 100%;
`;

export const ProductsContainer = styled.div`
  width: 100%;
  display: grid;
  box-sizing: border-box;
  color: white;
  ${onSmallScreen(css`
    border-top: 1px solid black;
    grid-template-columns: ${(props) => props.theme.design.columns.small};
    overflow-x: hidden;
    grid-gap: 1px;
  `)}

  ${onLargeScreen(css`
    grid-template-columns: 1fr 1fr 1fr;
  `)}
`;

export const ProductsLookbookChapter = styled.div`
  width: 100%;
  ${font.Large};
  text-transform: uppercase;
  background-color: ${colorUsage.burgundy};
  color: ${colorUsage.white};
  font-weight: ${fontWeight.bold};
  display: flex;
  justify-content: center;
  padding-top: ${getSpacing(5)};
  padding-bottom: ${getSpacing(5)};
  ${onSmallScreen(css`
    padding-top: ${getSpacing(4)};
    padding-bottom: ${getSpacing(4)};
  `)}
`;
