import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ReactComponent as NextArrow } from '@components/DesktopDoubleCarousel/arrow.svg';
import { ReactComponent as PrevArrow } from '@components/DesktopDoubleCarousel/prev-arrow.svg';
import {
  DoubleCarouselContainer,
  DoubleCarouselSlide,
  DoubleCarouselSlideImg
} from '@components/DesktopDoubleCarousel/DesktopDoubleCarousel.style';

const SampleNextArrow = (props: any) => <NextArrow onClick={props.onClick} className={props.className} />;

const SamplePrevArrow = (props: any) => <PrevArrow onClick={props.onClick} className={props.className} />;

interface Props {
  images: string[]
}

const DesktopDoubleCarousel = ({ images }: Props) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    accessibility: false,
    swipeToSlide: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <DoubleCarouselContainer>
      <Slider {...settings}>
        {images.length > 1 ? images.map((i) => (
          <DoubleCarouselSlide>
            <DoubleCarouselSlideImg src={i} alt="Product" />
          </DoubleCarouselSlide>
        )) : (
          <>
            <DoubleCarouselSlide>
              <DoubleCarouselSlideImg src={images[0]} alt="Product" />
            </DoubleCarouselSlide>
            <DoubleCarouselSlide>
              <DoubleCarouselSlideImg src={images[0]} alt="Product" />
            </DoubleCarouselSlide>
          </>
        )}
      </Slider>
    </DoubleCarouselContainer>
  );
};

export default DesktopDoubleCarousel;
