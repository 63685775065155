import styled from 'styled-components';

const HeaderLinksGroup = styled.div<{ isMobileVersion ?: boolean }>`
  width: auto;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding-right: ${(props) => props.isMobileVersion ? '20px' : '70px'};
`;

export default HeaderLinksGroup;
