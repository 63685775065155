export enum Currency {
  USD = 'USD',
  CAD = 'CAD',
  EUR = 'EUR',
  GBP = 'GBP',
  CHF = 'CHF',
  SAR = 'SAR',
  AED = 'AED',
  MXN = 'MXN',
  KRW = 'KRW',
  JPY = 'JPY',
  AUD = 'AUD',
  NZD = 'NZD',
  SGD = 'SGD',
  HKD = 'HKD',
  INR = 'INR',
  THB = 'THB',
}
