import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { colorUsage, fontFamily, fontSize, fontWeight, getSpacing, lineHeight, onLargeScreen } from '@stylesheet';
import BaseButton from '@components/Button/Button';
import { ReactComponent as LeftChevronIcon } from '@assets/chevron_left.svg';

export const Container = styled.div`
  background-color: ${colorUsage.background};
  font-family: ${fontFamily.main};
  overflow-x: hidden;
  height: auto;

  ${onLargeScreen(
    css`
      display: none;
    `,
  )}
`;

export const ProductInfoContainer = styled.div`
  padding: ${getSpacing(3)} ${getSpacing(4)};
  background-color: ${colorUsage.white};
`;

export const ProductHeader = styled.div`
  padding-top: ${getSpacing(1)};
  background-color: ${colorUsage.background};
  margin-left: 2.5%;
  margin-right: 2.5%;
  width: 95%;
  display: flex;
  justify-content: space-between;
`;

export const CarouselContainer = styled.div`
  width: 100%;
  height: auto;
  overflow: hidden;
`;

export const CarouselPaginationContainer = styled.div`
  width: 100%;
  display: flex;
`;

export const CarouselPagination = styled.p`
  margin: auto;
`;

export const MobileCarouselImg = styled.img`
  width: 70%;
  height: auto;
  margin: auto;
`;

export const ProductHeaderItem = styled.div`
  text-decoration: underline;
  justify-content: left;
  margin-top: auto;
  margin-bottom: auto;
`;

export const ProductTitle = styled.h1`
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.large};
  text-align: left;

  ${theme('language', {
    ar: css`
      text-align: right;
      direction: rtl;
    `,
  })}
`;

export const ProductName = styled.h1`
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.medium};
  line-height: ${lineHeight.large};
  text-align: center;

  ${theme('language', {
    ar: css`
      text-align: right;
      direction: rtl;
    `,
  })}
`;

const sharedCss = css`
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.medium};
  line-height: ${lineHeight.large};
`;

export const ProductDescription = styled.div`
  font-size: ${fontSize.medium};
  line-height: ${lineHeight.medium};
  margin: ${getSpacing(3)} 0 ${getSpacing(1)} 0;
  text-align: left;
  white-space: break-spaces;

  ${theme('language', {
    ar: css`
      text-align: right;
      direction: rtl;
    `,
  })}
`;

export const BulletPointsContainer = styled.div`
  margin-top: ${getSpacing(3)};
  display: flex;
`;

export const BulletPoints = styled.ul`
  width: 90%;
  margin: auto;
`;

export const Text = styled.div`
  ${sharedCss};
  margin-top: ${getSpacing(1)};

  ${theme('language', {
    ar: css`
      text-align: right;
      direction: rtl;
    `,
  })}
`;

export const Slide = styled.div<{ windowWidth?: number }>`
  width: 100%;
  height: auto;
  display: flex;
  &:focus {
    outline: none;
  }
`;

export const PriceContainer = styled.div`
  background-color: ${colorUsage.background};
  width: 100vw;
  height: auto;
`;

export const PriceContent = styled.div`
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: ${getSpacing(4)};
`;

export const ProductPrice = styled.h2`
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.medium};
  text-align: center;
  margin-top: ${getSpacing(2)}

  ${theme('language', {
    ar: css`
      text-align: right;
      direction: rtl;
    `,
  })}
`;

export const Button = styled(BaseButton)`
  width: 100%;
  margin: auto;
  margin-top: ${getSpacing(2)};
  max-width: none;
  font-size: ${fontSize.XSmall};
`;

export const GoBackContainer = styled.div`
  display: flex;
  color: ${colorUsage.black};
  ${theme('language', {
    ar: css`
      text-align: right;
      direction: rtl;
    `,
  })}
  
  &:hover {
    cursor: pointer;
  }
`;

export const StyledLeftChevronIcon = styled(LeftChevronIcon)`
  margin-top: auto;
  margin-bottom: auto;
  fill: ${colorUsage.black};
`;

export const GoBackText = styled.p`
  margin-top: auto;
  margin-bottom: auto;
  text-transform: uppercase;
  font-weight: ${fontWeight.bold};
  font-size: 14px;
  padding-left: 8px;
  padding-top: 4px;
`;
