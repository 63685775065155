import React, { useEffect, useState } from 'react';

import HeartEmptyGrey from '@assets/like.svg';
import Heart from '@assets/likeFull.svg';

import { useUpdateFeedback } from '@redux/caRecommendation/hooks';
import { Favorite } from '@components/LikeButton/LikeButton.style';

interface Props {
  caRecommendationId: string;
  styleColorCode: string;
  initialIsLiked: boolean;
  className?: string;
  setLiked?: React.Dispatch<React.SetStateAction<boolean>>;
  dynamicIsLiked?: boolean
}

const LikeButton: React.FC<Props> = ({
  caRecommendationId,
  styleColorCode,
  initialIsLiked,
  className,
  setLiked,
  dynamicIsLiked
}) => {
  const [, updateFeedback] = useUpdateFeedback();

  const [isLiked, setIsLiked] = useState<boolean>(initialIsLiked);

  const giveFeedback = async (feedback: number | null) => {
    await updateFeedback(caRecommendationId, styleColorCode, feedback);
  };

  const like = async (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsLiked(true);
    if (setLiked) {
      setLiked(true);
    }
    await giveFeedback(4);
  };
  const unLike = async (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsLiked(false);
    if (setLiked) {
      setLiked(false);
    }
    await giveFeedback(null);
  };

  useEffect(() => {
    setIsLiked(dynamicIsLiked ?? initialIsLiked);
  }, [dynamicIsLiked]);

  return isLiked ? (
    <Favorite src={Heart} alt="Remove from liked products" onClick={unLike} className={className} />
  ) : (
    <Favorite
      src={HeartEmptyGrey}
      alt="Add to liked products"
      onClick={like}
      className={className}
    />
  );
};

export default LikeButton;
