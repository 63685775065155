import React, { useEffect, useState } from 'react';
import { Product } from '@redux/caRecommendation/types';

import {
  Button,
  CarouselAndImageContainer,
  Container,
  GoBackContainer,
  GoBackText,
  ImageAndSliderContainer,
  PriceContainer,
  PriceContent,
  ProductDescriptionContainer,
  ProductDescriptionLeft,
  ProductDescriptionRight,
  ProductHeader,
  ProductHeaderContainer,
  ProductHeaderItem,
  ProductInfoContainer,
  ProductName,
  ProductPrice,
  ProductTitle,
  StyledLeftChevronIcon
} from '@pages/ProductDetailPage/components/DesktopProductDetailPage/DesktopProductDetailPage.style';
import { useSelector } from 'react-redux';
import { getLanguage } from '@redux/language/selectors';
import { ThemeProvider } from 'styled-components/macro';
import { getPriceWithCurrency } from '@services/product/productService';
import DesktopDoubleCarousel from '@components/DesktopDoubleCarousel';
import Loader from '@components/Loader';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import LikeButton from '@components/LikeButton';

interface Props {
  product: Product;
  caRecommendationId: string;
}

function loadImage(url: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const loadImg = new Image();
    loadImg.src = url;
    loadImg.onload = () => resolve(url);
    loadImg.onerror = (err) => reject(err);
  });
}

const DesktopProductDetailPage: React.FunctionComponent<Props> = ({
  product,
  caRecommendationId
}) => {
  const [areImagesLoaded, setAreImagesLoaded] = useState(false);
  const language = useSelector(getLanguage)?.language.locale;
  const navigate = useNavigate();
  const initialLiked: boolean = Boolean(product.feedback && product.feedback >= 4);
  const [isLiked, setIsLiked] = useState<boolean>(initialLiked);

  const checkImages = async () => {
    for (let i = 0; i < product.externalMediaUrls.length; i += 1) {
      const url = product.externalMediaUrls[i];
      await loadImage(url);
    }
    setAreImagesLoaded(true);
  };

  useEffect(() => {
    checkImages();
  }, []);

  const text: string = product.description ?? '';

  const indexFirstPart: number = text.indexOf(';');

  const part1: string = text.substring(0, indexFirstPart);
  const part2: string = text.substring(indexFirstPart + 1);

  const bulletPoints = part2.split(';')
    .map((point, index) => <li key={index}>{point.trim()}</li>);

  return (
    <>
      {!areImagesLoaded ? <Loader /> : (
        <ThemeProvider theme={{ language }}>
          <Container>
            <CarouselAndImageContainer>
              <ProductHeaderContainer>
                <ProductHeader>
                  <GoBackContainer onClick={() => navigate(`/suggestion/${caRecommendationId}?page=list`)}>
                    <StyledLeftChevronIcon />
                    <GoBackText>
                      <FormattedMessage id="product-details.back-products-selection" />
                    </GoBackText>
                  </GoBackContainer>
                  <ProductHeaderItem>
                    <LikeButton
                      caRecommendationId={caRecommendationId}
                      styleColorCode={product.styleColorCode}
                      initialIsLiked={!!initialLiked}
                      setLiked={setIsLiked}
                      dynamicIsLiked={isLiked}
                    />
                  </ProductHeaderItem>
                </ProductHeader>
              </ProductHeaderContainer>
              <ImageAndSliderContainer>
                <DesktopDoubleCarousel images={product.externalMediaUrls} />
                <PriceContainer>
                  <PriceContent>
                    <ProductName>{product.name}</ProductName>
                    <ProductPrice>{getPriceWithCurrency(product.priceAmount, product.priceCurrency)}</ProductPrice>
                  </PriceContent>
                </PriceContainer>
              </ImageAndSliderContainer>
            </CarouselAndImageContainer>
            <ProductInfoContainer>
              <ProductTitle>
                <FormattedMessage id="product-details.product-details" />
              </ProductTitle>
              <ProductDescriptionContainer>
                <ProductDescriptionLeft>
                  {part1}
                  <a href={product.url} target="_blank" rel="noreferrer">
                    <Button>
                      <FormattedMessage id="product-details.buy" />
                    </Button>
                  </a>
                </ProductDescriptionLeft>
                <ProductDescriptionRight>
                  <ul>
                    {bulletPoints}
                    {product.styleCode
                      && (
                        <li>
                          <FormattedMessage id="product-details.style-code" values={{ value: product.styleCode }} />
                        </li>
                      )}
                    {product.skus && product.skus.length > 0
                      && (
                        <li>
                          <FormattedMessage id="product-details.sku" values={{ value: product.skus[0].sku }} />
                        </li>
                      )}
                  </ul>
                </ProductDescriptionRight>
              </ProductDescriptionContainer>
            </ProductInfoContainer>
          </Container>
        </ThemeProvider>
      )}
    </>
  );
};

export default DesktopProductDetailPage;
