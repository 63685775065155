import { useState, useEffect, useCallback } from 'react';
import { LargeScreenLimit } from '@stylesheet';

export const useWindowSize = (): [
  {
    width: number | undefined;
    height: number | undefined;
  },
  () => boolean,
] => {
  const isClient = typeof window === 'object';

  const getSize = useCallback(() => ({
    width: isClient ? window.outerWidth : undefined,
    height: isClient ? window.outerHeight : undefined,
  }), [isClient]);

  const [windowSize, setWindowSize] = useState(getSize);

  const handleResize = () => {
    setWindowSize(getSize());
  };

  useEffect(
    () => {
      if (isClient) {
        window.addEventListener('resize', handleResize);
      }
      return () => window.removeEventListener('resize', handleResize);
    },
    // Don't add any other dependency than getSize and isClient has we want this effect to run only on mount and unmount
    [getSize, isClient],
  );

  const isLargeScreen = () => windowSize.width ? windowSize.width >= LargeScreenLimit : false;

  return [windowSize, isLargeScreen];
};
