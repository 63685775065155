import { BrandConfiguration } from '@redux/brandConfiguration/types';
import { getSpacing } from '@stylesheet';
import GGMobileMainBackground from '@assets/mobileBackground.jpg';
import GGDesktopMainBackground from '@assets/reco_desk.jpg';
import BVDesktopMainBackground from '@assets/pr2.jpg';

export const defaultConfiguration: BrandConfiguration = {
  constants: {
    name: 'default',
    url: ''
  },
  features: {
    favorite: true,
    productDetail: true
  },
  design: {
    background: {
      small: `${GGMobileMainBackground}`,
      large: `${GGDesktopMainBackground}`
    },
    columns: {
      small: '[first] 50% [middle] 50% [end]',
      large: '[first] 33% [second] 33% [third] 33% [end]'
    },
    rows: {
      small: `[first] ${getSpacing(33)} [second] ${getSpacing(33)} [third] ${getSpacing(33)} [fourth] ${getSpacing(33)} [end]`,
      large: `[first] ${getSpacing(60)} [second] ${getSpacing(60)} [third] ${getSpacing(60)} [fourth] ${getSpacing(60)} [end]`
    }
  }
};

export const ggConfiguration: BrandConfiguration = {
  constants: {
    name: 'GG',
    url: 'https://www.gucci.com'
  },
  features: {
    favorite: true,
    productDetail: true
  },
  design: {
    background: {
      small: `${GGMobileMainBackground}`,
      large: `${GGDesktopMainBackground}`
    },
    columns: {
      small: '[first] 50% [middle] 50% [end]',
      large: '[first] 33% [second] 33% [third] 33% [end]'
    },
    rows: {
      small: `[first] ${getSpacing(33)} [second] ${getSpacing(33)} [third] ${getSpacing(33)} [fourth] ${getSpacing(33)} [end]`,
      large: `[first] ${getSpacing(60)} [second] ${getSpacing(60)} [third] ${getSpacing(60)} [fourth] ${getSpacing(60)} [end]`
    }
  }
};

export const bvConfiguration: BrandConfiguration = {
  constants: {
    name: 'BV',
    url: 'https://www.bottegaveneta.com/'
  },
  features: {
    favorite: false,
    productDetail: false
  },
  design: {
    background: {
      small: `${BVDesktopMainBackground}`,
      large: `${BVDesktopMainBackground}`
    },
    columns: {
      small: '[first] 33% [second] 33% [third] 33% [end]',
      large: '[first] 33% [second] 33% [third] 33% [end]'
    },
    rows: {
      small: `[first] ${getSpacing(33)} [second] ${getSpacing(33)} [third] ${getSpacing(33)} [fourth] ${getSpacing(33)} [end]`,
      large: `[first] ${getSpacing(33)} [second] ${getSpacing(33)} [third] ${getSpacing(33)} [fourth] ${getSpacing(33)} [end]`
    }
  }
};
