import { keyframes } from 'styled-components';

export const textAnimationIn = (backgroundColor: string) => keyframes`
  0% {
    opacity: 0;
    max-height: 0;
  }
  100% {
    opacity: 1;
    max-height: 100px;
    transform: translate(0);
    background-color: ${backgroundColor};
  }
`;

export const textAnimationOut = keyframes`
  0% {
    opacity: 1;
    max-height: 100px;
    transform: translate(0);
  }
  100% {
    opacity: 0;
    max-height: 0;
  }
`;
