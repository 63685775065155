import styled from 'styled-components';
import { getSpacing } from '@stylesheet';

import { ReactComponent as Logo } from '@assets/gucci_logo.svg';

const StyledLogo = styled(Logo)`
  min-height: ${getSpacing(4)};
  width: ${getSpacing(21)};
  margin-top: 50px;
  filter: invert(1);
`;

export default StyledLogo;
