import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom/client';
import '@/index.css';
import { store } from '@redux/store';
import App from '@/App';

declare global {
  interface Window {
    _env_: {
      [env: string]: string | undefined;
    };
  }
}
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <App store={store} />
  </React.StrictMode>
);
