import React, { useState } from 'react';

import MobileCarousel from '@components/MobileCarousel';
import { useWindowSize } from '@services/hooks/useWindowSize';
import { Product } from '@redux/caRecommendation/types';

import {
  BulletPoints,
  BulletPointsContainer,
  Button,
  CarouselContainer,
  CarouselPagination,
  CarouselPaginationContainer,
  Container,
  GoBackContainer,
  GoBackText,
  MobileCarouselImg,
  PriceContainer,
  PriceContent,
  ProductDescription,
  ProductHeader,
  ProductHeaderItem,
  ProductInfoContainer,
  ProductName,
  ProductPrice,
  ProductTitle,
  Slide,
  StyledLeftChevronIcon
} from '@pages/ProductDetailPage/components/MobileProductDetailPage/MobileProductDetailPage.style';
import { ThemeProvider } from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { getLanguage } from '@redux/language/selectors';
import { getPriceWithCurrency } from '@services/product/productService';
import { FormattedMessage } from 'react-intl';
import LikeButton from '@components/LikeButton';
import { useNavigate } from 'react-router-dom';

interface Props {
  product: Product;
  caRecommendationId: string;
}

const MobileProductDetailPage: React.FunctionComponent<Props> = ({
  product,
  caRecommendationId,
}) => {
  const navigate = useNavigate();
  const [windowSize] = useWindowSize();
  const initialLiked = Boolean(product.feedback && product.feedback >= 4);
  const language = useSelector(getLanguage)?.language.locale;

  const [isLiked, setIsLiked] = useState<boolean>(initialLiked);

  const [activeIndex, setActiveIndex] = useState(0);

  const text: string = product.description ?? '';

  const indexFirstPart: number = text.indexOf(';');

  const part1: string = text.substring(0, indexFirstPart);
  const part2: string = text.substring(indexFirstPart + 1);

  const bulletPoints = part2.split(';')
    .map((point, index) => <li key={index}>{point.trim()}</li>);

  return (
    <ThemeProvider theme={{ language }}>
      <Container>
        <ProductHeader>
          <GoBackContainer onClick={() => navigate(`/suggestion/${caRecommendationId}?page=list`)}>
            <StyledLeftChevronIcon />
            <GoBackText>
              <FormattedMessage id="product-details.back-products-selection" />
            </GoBackText>
          </GoBackContainer>
          <ProductHeaderItem>
            <LikeButton
              caRecommendationId={caRecommendationId}
              styleColorCode={product.styleColorCode}
              initialIsLiked={!!initialLiked}
              setLiked={setIsLiked}
              dynamicIsLiked={isLiked}
            />
          </ProductHeaderItem>
        </ProductHeader>

        <CarouselContainer>
          <MobileCarousel windowWidth={windowSize.width} setActiveIndex={setActiveIndex}>
            {product.externalMediaUrls.map((url) => (
              <Slide key={url} windowWidth={windowSize.width}>
                <MobileCarouselImg src={url} alt="product" />
              </Slide>
            ))}
          </MobileCarousel>
          <CarouselPaginationContainer>
            <CarouselPagination>{`${activeIndex + 1}/${product.externalMediaUrls.length ?? 0}`}</CarouselPagination>
          </CarouselPaginationContainer>

        </CarouselContainer>

        <PriceContainer>
          <PriceContent>
            <ProductName>{product.name}</ProductName>
            <ProductPrice>{getPriceWithCurrency(product.priceAmount, product.priceCurrency)}</ProductPrice>
          </PriceContent>
        </PriceContainer>
        <ProductInfoContainer>
          <ProductTitle><FormattedMessage id="product-details.product-details" /></ProductTitle>
          <ProductDescription>
            {part1}
            <BulletPointsContainer>
              <BulletPoints>
                {bulletPoints}
                {product.styleCode
                  && (
                    <li>
                      <FormattedMessage id="product-details.style-code" values={{ value: product.styleCode }} />
                    </li>
                  )}
                {product.skus && product.skus.length > 0
                  && (
                    <li>
                      <FormattedMessage id="product-details.sku" values={{ value: product.skus[0].sku }} />
                    </li>
                  )}
              </BulletPoints>
            </BulletPointsContainer>
            <a href={product.url} target="_blank" rel="noreferrer">
              <Button>
                <FormattedMessage id="product-details.buy" />
              </Button>
            </a>
          </ProductDescription>
        </ProductInfoContainer>
      </Container>
    </ThemeProvider>
  );
};

export default MobileProductDetailPage;
