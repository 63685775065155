import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { useSearchParam } from 'react-use';

import { useFetchCaRecommendation } from '@redux/caRecommendation/hooks';
import { getCaRecommendation } from '@redux/caRecommendation/selectors';

import Loader from '@components/Loader';
import Introduction from '@pages/Suggestion/components/Introduction';
import ProductList from '@pages/Suggestion/components/ProductList';
import { Container } from '@pages/Suggestion/Suggestion.style';
import { PATHS } from '@routes';
import { DesktopNavBar, MobileNavBar } from '@components/NavBar';
import Functions from '@utils/Functions';
import { Footer } from '@components/Footer';
import { RECOMMENDATION_TYPE } from '@redux/caRecommendation/types';
import ContactBubble from '@components/ContactBubble';
import ContactModal from '@components/ContactModal';

export const LIST_PAGE = 'list';

type ISuggestion = {
  caRecommendationId: string;
}

const Suggestion: React.FC = () => {
  const { caRecommendationId } = useParams<ISuggestion>();
  const dispatch = useDispatch();

  const page = useSearchParam('page');

  const [fetchCaRecommendationResponse, fetchCaRecommendation] = useFetchCaRecommendation();
  const caRecommendation = useSelector(getCaRecommendation);
  const caRecommendationAssociated = caRecommendation?.recommendationId === caRecommendationId ? caRecommendation : null;
  const isLookbook = caRecommendation?.recommendationType === RECOMMENDATION_TYPE.LOOKBOOK ?? false;
  const [openContactModal, setOpenContactModal] = useState(false);

  const [selectionIsVisible, setSelectionIsVisible] = useState(page === LIST_PAGE);
  const [isDesktopVersion, setIsDesktopVersion] = useState(Functions.isDesktopVersion());

  useEffect(() => {
    (async function fetch() {
      if (!caRecommendationAssociated && caRecommendationId) {
        await fetchCaRecommendation(caRecommendationId);
      }
    }());
  }, [caRecommendationId, dispatch, fetchCaRecommendation, caRecommendationAssociated]);

  useEffect(() => {
    if (isLookbook) {
      setSelectionIsVisible(true);
    }
  }, [isLookbook]);

  useEffect(() => {
    const handleSetIsDesktopVersion = () => setIsDesktopVersion(Functions.isDesktopVersion());
    window.addEventListener('resize', handleSetIsDesktopVersion);
    return () => {
      window.removeEventListener('resize', handleSetIsDesktopVersion);
    };
  });

  if (!caRecommendationAssociated) {
    if (fetchCaRecommendationResponse.error) {
      return <Navigate to={PATHS.ERROR} />;
    }

    return <Loader />;
  }

  return (
    <Container>
      <div>
        <DesktopNavBar caRecommendationId={caRecommendationId} />
        {selectionIsVisible && <MobileNavBar caRecommendationId={caRecommendationId} />}
        <CSSTransition in={!selectionIsVisible && !isDesktopVersion && !isLookbook} timeout={600} classNames="introduction" unmountOnExit>
          <Introduction goToSelection={() => setSelectionIsVisible(true)} />
        </CSSTransition>
        <CSSTransition in={selectionIsVisible || isDesktopVersion} timeout={isLookbook ? 0 : 600} classNames="product-list" unmountOnExit>
          <ProductList caRecommendation={caRecommendationAssociated} isDesktopVersion={isDesktopVersion} />
        </CSSTransition>
        {isLookbook && <ContactBubble action={() => setOpenContactModal(!openContactModal)} isOpen={openContactModal} image="" />}
      </div>
      <Footer />
      {caRecommendation?.advisor
        && <ContactModal open={openContactModal} advisor={caRecommendation.advisor} />}
    </Container>
  );
};

export default Suggestion;
