import { Currency } from '@constants/currency';

export const getPriceWithCurrency = (amount: number, currency: Currency) => {
  switch (currency) {
    case Currency.EUR:
      return `€ ${amount}`;
    case Currency.USD:
      return `$ ${amount}`;
    case Currency.GBP:
      return `£ ${amount}`;
    case Currency.CHF:
      return `CHF ${amount}`;
    case Currency.CAD:
      return `C$ ${amount}`;
    case Currency.SAR:
      return `SAR ${amount}`;
    case Currency.AED:
      return `AED ${amount}`;
    case Currency.MXN:
      return `MXN ${amount}`;
    case Currency.KRW:
      return `₩ ${amount}`;
    case Currency.JPY:
      return `¥ ${amount}`;
    case Currency.AUD:
      return `AU$ ${amount}`;
    case Currency.NZD:
      return `NZ$ ${amount}`;
    case Currency.SGD:
      return `S$ ${amount}`;
    case Currency.HKD:
      return `HK$ ${amount}`;
    case Currency.INR:
      return `INR ${amount}`;
    case Currency.THB:
      return `THB ${amount}`;
    default:
      return amount;
  }
};
