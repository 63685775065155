import styled, { css } from 'styled-components';
import { colorUsage, onLargeScreen, onSmallScreen } from '@stylesheet';
import { ReactComponent as Logo } from '@assets/gucci_logo.svg';

export const FooterContainer = styled.div`
  bottom: 0;
  width: 100%;
  height: auto;
  background-color: ${colorUsage.black};
  display: flex;
`;

export const GucciFooterLogo = styled(Logo)`
  padding-top: 50px;
  padding-bottom: 50px;
  filter: invert(1);
  ${onSmallScreen(css`
    margin: auto;
    height: auto;
    width: 90%;
  `)}
  ${onLargeScreen(css`
    padding-left: 50px;
    width: 350px;
  `)}
`;
