import React from 'react';
import { PublicAdvisor } from '@redux/advisors/types';
import {
  AdvisorContact,
  AdvisorContactLink,
  AdvisorContactsContainer,
  AdvisorContactText,
  AdvisorContainer,
  AdvisorFunction,
  AdvisorImage,
  AdvisorImageContainer,
  AdvisorInformations,
  AdvisorName,
  ContactIcon,
  ModalContainer,
  ModalContent,
  StyledDesktopLineIcon,
  StyledDesktopMailIcon,
  StyledDesktopNumberIcon,
  StyledDesktopSmsIcon,
  StyledDesktopWhatsAppIcon,
} from '@components/ContactModal/style';
import { useSelector } from 'react-redux';
import { getLanguage } from '@redux/language/selectors';
import { ThemeProvider } from 'styled-components/macro';
import gucciLogo from '@assets/bubble_icon.png';

type Props = {
  open: boolean
  advisor: PublicAdvisor
}

const ContactModal: React.FC<Props> = ({
  open,
  advisor
}) => {
  const language = useSelector(getLanguage)?.language.locale;

  return (
    <>
      {open
        && (
          <ThemeProvider theme={{ language }}>
            <ModalContainer>
              <ModalContent>
                <AdvisorContainer>
                  <AdvisorInformations>
                    <AdvisorImageContainer>
                      {/* TODO Replace image */}
                      <AdvisorImage src={gucciLogo} alt="" />
                    </AdvisorImageContainer>
                    <AdvisorName>{advisor?.displayName}</AdvisorName>
                    <AdvisorFunction>{advisor?.businessCardRole}</AdvisorFunction>
                  </AdvisorInformations>
                  <AdvisorContactsContainer>
                    {advisor?.workPhoneNumber && (
                      <AdvisorContact>
                        <ContactIcon>
                          <StyledDesktopNumberIcon />
                        </ContactIcon>
                        <AdvisorContactText><AdvisorContactLink href={`tel:${advisor.workPhoneNumber}`} rel="noopener noreferrer" target="_blank">{advisor?.workPhoneNumber}</AdvisorContactLink></AdvisorContactText>
                      </AdvisorContact>
                    )}
                    {advisor?.workPhoneNumber && (
                      <AdvisorContact>
                        <ContactIcon>
                          <StyledDesktopSmsIcon />
                        </ContactIcon>
                        <AdvisorContactText><AdvisorContactLink href={`sms:${advisor.workPhoneNumber}`} rel="noopener noreferrer" target="_blank">{advisor?.workPhoneNumber}</AdvisorContactLink></AdvisorContactText>
                      </AdvisorContact>
                    )}
                    {advisor?.email && (
                      <AdvisorContact>
                        <ContactIcon>
                          <StyledDesktopMailIcon />
                        </ContactIcon>
                        <AdvisorContactText><AdvisorContactLink href={`mailto:${advisor.line}`} rel="noopener noreferrer" target="_blank">{advisor?.email}</AdvisorContactLink></AdvisorContactText>
                      </AdvisorContact>
                    )}
                    {advisor?.whatsApp && (
                      <AdvisorContact>
                        <ContactIcon>
                          <StyledDesktopWhatsAppIcon />
                        </ContactIcon>
                        <AdvisorContactText><AdvisorContactLink href={advisor.whatsApp} rel="noopener noreferrer" target="_blank">{advisor?.workPhoneNumber}</AdvisorContactLink></AdvisorContactText>
                      </AdvisorContact>
                    )}
                    {advisor?.line && (
                      <AdvisorContact>
                        <ContactIcon>
                          <StyledDesktopLineIcon />
                        </ContactIcon>
                        <AdvisorContactText><AdvisorContactLink href={advisor.line} rel="noopener noreferrer" target="_blank">{advisor?.linePseudo}</AdvisorContactLink></AdvisorContactText>
                      </AdvisorContact>
                    )}
                  </AdvisorContactsContainer>
                </AdvisorContainer>
              </ModalContent>
            </ModalContainer>
          </ThemeProvider>
        )}
    </>
  );
};

export default ContactModal;
