import styled, { css } from 'styled-components';
import { colorUsage, font, fontFamily, fontSize, fontWeight, getSpacing, lineHeight, onSmallScreen } from '@stylesheet';

import { ReactComponent as LeftChevronIcon } from '@assets/chevron_left.svg';
import theme from 'styled-theming';
import BaseButton from '@components/Button/Button';

export const Container = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  font-family: ${fontFamily.main};
  height: auto;

  ${onSmallScreen(css`
    display: none;
  `)}
`;

export const CarouselAndImageContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  overflow: hidden;
`;

export const ImageAndSliderContainer = styled.div`
  margin: 0;
  width: 100%;
  max-width: 100vw;
  background-color: ${colorUsage.background};
  padding-top: 10vw;
  padding-bottom: 8vw;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const PriceContainer = styled.div`
  background-color: ${colorUsage.white};
  width: 20vw;
  height: auto;
  position: absolute;
  padding: ${getSpacing(3)};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const PriceContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ProductInfoContainer = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: ${getSpacing(6)};
  margin-right: ${getSpacing(6)};
`;

export const ProductName = styled.h1`
  font-weight: ${fontWeight.bold};
  ${font.Large};
  text-align: center;

  ${theme('language', {
    ar: css`
      text-align: right;
      direction: rtl;
    `,
  })}
`;

export const ProductTitle = styled.h1`
  font-weight: ${fontWeight.bold};
  ${font.Large};
  text-align: left;

  ${theme('language', {
    ar: css`
      text-align: right;
      direction: rtl;
    `,
  })}
`;

export const ProductPrice = styled.h2`
  font-weight: ${fontWeight.bold};
  ${font.Large};
  text-align: center;
  margin-top: ${getSpacing(2)}

  ${theme('language', {
    ar: css`
      text-align: right;
      direction: rtl;
    `,
  })}
`;

export const Button = styled(BaseButton)`
  margin-top: ${getSpacing(2)};
  max-width: none;
`;

const sharedCss = css`
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.medium};
  line-height: ${lineHeight.mediumLarge};
`;

export const ProductDescriptionContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const ProductDescription = styled.div`
  font-size: ${fontSize.medium};
  line-height: ${lineHeight.mediumLarge};
  width: 50%;
  margin: ${getSpacing(3)} 0 ${getSpacing(5)} 0;
  text-align: justify;
  white-space: break-spaces;

  ${theme('language', {
    ar: css`
      text-align: right;
      direction: rtl;
    `,
  })}
`;

export const ProductDescriptionLeft = styled(ProductDescription)`
  padding-right: ${getSpacing(4)};
`;

export const ProductDescriptionRight = styled(ProductDescription)`
  padding-left: ${getSpacing(4)};
`;

export const Text = styled.div`
  ${sharedCss};
  margin-top: ${getSpacing(1)};

  ${theme('language', {
    ar: css`
      text-align: right;
      direction: rtl;
    `,
  })}
`;

export const ProductHeaderContainer = styled.div`
  padding-top: ${getSpacing(2)};
  background-color: ${colorUsage.background};
  width: 100%;
`;

export const ProductHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: ${getSpacing(4)};
  padding-right: ${getSpacing(4)};
`;

export const ProductHeaderItem = styled.div`
  text-decoration: underline;
  justify-content: left;
  margin-top: auto;
  margin-bottom: auto;
`;

export const GoBackContainer = styled.div`
  display: flex;
  color: ${colorUsage.black};
  ${theme('language', {
    ar: css`
      text-align: right;
      direction: rtl;
    `,
  })}
  
  &:hover {
    cursor: pointer;
  }
`;

export const StyledLeftChevronIcon = styled(LeftChevronIcon)`
  margin-top: auto;
  margin-bottom: auto;
  fill: ${colorUsage.black};
`;

export const GoBackText = styled.p`
  margin-top: auto;
  margin-bottom: auto;
  text-transform: uppercase;
  font-weight: ${fontWeight.bold};
  font-size: 14px;
  padding-left: 8px;
  padding-top: 4px;
`;
