import styled, { css } from 'styled-components';
import { colorUsage, fontWeight, getSpacing, onLargeScreen, onSmallScreen } from '@stylesheet';

export const StyledNavBar = styled.div<{ contactMethodNumber?: number, isDesktopVersion?: boolean }>`
  position: fixed;
  width: 100vw;
  display: flex;
  z-index: 1;
  top: 0;
  height: ${(props) => props.isDesktopVersion ? '70px' : '50px'};
  background-color: white;
  text-transform: uppercase;
  border-bottom: 1px solid ${colorUsage.black};
  justify-content: space-between;
`;

export const DesktopContainer = styled.div`
  overflow: hidden;
  z-index: 1;

  ${onSmallScreen(css`
    display: none
  `)}
`;

export const MobileContainer = styled.div`
  ${onLargeScreen(css`
    display: none
  `)}
`;

export const StyledText = styled.p`
  margin-right: ${getSpacing(1)};
  font-weight: ${fontWeight.bold} ;
  font-size: 16px;
`;

export const StyledLink = styled.a<{
  backgroundColor?: string;
  marginLeft?: string;
}>`
  color: black;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 12pt;
  cursor: pointer;
  margin-left: ${(props) => props.marginLeft};
  background-color: ${(props) => props.backgroundColor};
  ${onSmallScreen(css`
    font-size: 7pt;
  `)}
`;

export const HeaderFavoriteContainer = styled.div<{
  isDesktopVersion: boolean;
}>`
  height: ${(props) => props.isDesktopVersion ? '70px' : '50px'};
  display: flex;
  justify-content: center;
`;

export const StyledMyFavorites = styled.img`
  margin: auto;
  ${onLargeScreen(css`
    width: ${getSpacing(3)};
  `)}
`;
