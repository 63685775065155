import flattenMessages from '@services/i18n/intl';
import arMessages from '@translations/ar.json';
import deMessages from '@translations/de.json';
import enMessages from '@translations/en.json';
import esMessages from '@translations/es.json';
import esmxMessages from '@translations/esmx.json';
import frMessages from '@translations/fr.json';
import itMessages from '@translations/it.json';
import cnMessages from '@translations/cn.json';
import jpMessages from '@translations/jp.json';
import krMessages from '@translations/kr.json';
import thMessages from '@translations/th.json';

export const locales = {
  ar: flattenMessages(arMessages),
  de: flattenMessages(deMessages),
  en: flattenMessages(enMessages),
  es: flattenMessages(esMessages),
  esmx: flattenMessages(esmxMessages),
  fr: flattenMessages(frMessages),
  it: flattenMessages(itMessages),
  cn: flattenMessages(cnMessages),
  jp: flattenMessages(jpMessages),
  kr: flattenMessages(krMessages),
  th: flattenMessages(thMessages),
};
