import styled, { css } from 'styled-components';

import { colorUsage, font, fontWeight, getSpacing, onLargeScreen, onSmallScreen } from '@stylesheet';
import { ReactComponent as LeftChevronIcon } from '@assets/chevron_left.svg';
import theme from 'styled-theming';

export const Container = styled.div`
  position: absolute;
  font-family: "guccisans", serif;
  min-height: 100%;
  height: auto;
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  ${onLargeScreen(css`
    overflow: overlay;
    overflow: -moz-scrollbars-vertical;
  `)}
`;

export const Content = styled.div`
  background-color: ${colorUsage.white};
  height: 100%;
  width: 100%;
  ${onLargeScreen(css`
    padding-top: 20px;
  `)}
`;

export const TitleContainer = styled.div`
  font-weight: ${fontWeight.bold};

  padding: 0 ${getSpacing(6)} ${getSpacing(4)} ${getSpacing(1)};

  ${onLargeScreen(css`
    padding: 0 ${getSpacing(2)} ${getSpacing(4)} ${getSpacing(5)};
  `)}
  
  ${font.XLarge}

  ${theme('language', {
    ar: css`
      text-align: right;
      direction: rtl;
    `,
  })}
`;

export const ProductsContainer = styled.div`
  width: 100%;
  display: grid;
  box-sizing: border-box;
  color: white;
  ${onSmallScreen(css`
    grid-template-columns: 1fr 1fr;
    grid-gap: 1px;
  `)}
  ${onLargeScreen(css`
    grid-template-columns: 1fr 1fr 1fr;
  `)}
`;

export const GoBackContainer = styled.div`
  padding: ${getSpacing(10)} ${getSpacing(6)} ${getSpacing(4)} ${getSpacing(1)};
  display: flex;
  color: ${colorUsage.black};

  ${onLargeScreen(css`
    padding: ${getSpacing(10)} ${getSpacing(2)} ${getSpacing(4)} ${getSpacing(5)};
  `)}
  ${theme('language', {
    ar: css`
      text-align: right;
      direction: rtl;
    `,
  })}
  
  &:hover {
    cursor: pointer;
  }
`;

export const StyledLeftChevronIcon = styled(LeftChevronIcon)`
  margin-top: auto;
  margin-bottom: auto;
  fill: ${colorUsage.black};
`;

export const GoBackText = styled.p`
  margin-top: auto;
  margin-bottom: auto;
  text-transform: uppercase;
  font-weight: ${fontWeight.bold};
  font-size: 14px;
  padding-left: 8px;
  padding-top: 4px;
`;
