import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ReactComponent as NextArrow } from '@components/MobileCarousel/nextMobile.svg';
import { ReactComponent as PrevArrow } from '@components/MobileCarousel/previousMobile.svg';
import { StyledCarousel } from './MobileCarousel.style';

const SampleNextArrow = (props: any) => <NextArrow onClick={props.onClick} className={props.className} />;

const SamplePrevArrow = (props: any) => <PrevArrow onClick={props.onClick} className={props.className} />;

type Props = {
  children: React.ReactNode;
  windowWidth?: number;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>
}

const MobileCarousel: React.FC<Props> = ({ children, windowWidth, setActiveIndex }) => {
  const settings = {
    accessibility: true,
    dots: false,
    slidesToScroll: 1,
    infinite: false,
    slidesToShow: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    afterChange: (index: number) => setActiveIndex(index),
  };

  return (
    <StyledCarousel width={windowWidth}>
      <Slider {...settings}>{children}</Slider>
    </StyledCarousel>
  );
};

export default MobileCarousel;
