import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CARecommendation } from '@redux/caRecommendation/types';
import { useSelector } from 'react-redux';
import { getCaRecommendation } from '@redux/caRecommendation/selectors';
import Functions from '@utils/Functions';
import { getLanguage } from '@redux/language/selectors';
import { ThemeProvider } from 'styled-components/macro';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';

import SocialContactGroup from '@components/SocialContactGroup';
import { AdvisorName, Greeting, Hello, IntroContainer, MySelection, StyledMessages, } from './Introduction.style';

interface Props {
  caRecommendation: CARecommendation;
  isDesktopVersion: boolean;
}

type SuggestionIntroductionType = React.FunctionComponent<Props>;

const Introduction: SuggestionIntroductionType = ({
  caRecommendation,
  isDesktopVersion
}) => (
  <GGIntroduction
    caRecommendation={caRecommendation}
    isDesktopVersion={isDesktopVersion}
  />
);

const GGIntroduction: SuggestionIntroductionType = ({
  caRecommendation,
  isDesktopVersion
}) => {
  const recommendation = useSelector(getCaRecommendation);
  const customer = recommendation?.clientDisplayName || recommendation?.clientLocalFirstName;
  const language = useSelector(getLanguage)?.language.locale;
  const { brandConfiguration } = useSelector(getBrandConfiguration);
  const customerGenderTranslation = caRecommendation?.clientGender === 'MALE' ? Functions.getGenderTranslation('MR') : Functions.getGenderTranslation(caRecommendation?.clientTitle);
  const isMySelectionGenderSpecific = language === 'ar';

  return (
    <ThemeProvider theme={{ language }}>
      <IntroContainer theme={brandConfiguration}>
        {caRecommendation?.greetings
          ? (
            <StyledMessages>
              <Hello>{caRecommendation.greetings.salutation}</Hello>
              <MySelection>{caRecommendation.greetings.body}</MySelection>
              <Greeting>
                {caRecommendation.greetings.signature}
                <br />
              </Greeting>
              <SocialContactGroup isDesktopVersion={isDesktopVersion} />
            </StyledMessages>
          )
          : (
            <StyledMessages>
              <Hello>
                {customerGenderTranslation && language !== 'kr' && language !== 'jp' && (
                  <FormattedMessage
                    id={customerGenderTranslation}
                    values={{
                      space: '\u00a0',
                    }}
                  />
                )}
                {customer && (
                  <FormattedMessage
                    id="product-list.hello"
                    values={{
                      clientFirstName: language === 'kr' ? Functions.toTitleCase(recommendation?.clientLocalFirstName) : Functions.toTitleCase(customer),
                      clientLastName: Functions.toTitleCase(recommendation?.clientDisplayName),
                      clientMiddleName: `${caRecommendation?.clientLocalMiddleName && caRecommendation?.clientLocalFirstName ? ' ' : ''}${Functions.toTitleCase(caRecommendation?.clientLocalMiddleName)}`,
                      linebreak: <br />,
                      space: '\u00a0',
                    }}
                  />
                )}
              </Hello>
              <MySelection>
                {isMySelectionGenderSpecific
                  ? (
                    <FormattedMessage
                      id={(caRecommendation?.clientGender !== undefined && caRecommendation?.clientGender !== 'MALE') ? 'product-list.my-selection.female' : 'product-list.my-selection.male'}
                      values={{
                        linebreak: <br />,
                        space: '\u00a0',
                      }}
                    />
                  ) : <FormattedMessage id="product-list.my-selection" values={{ linebreak: <br /> }} />}
              </MySelection>
              {language !== 'jp' && (
                <Greeting>
                  <FormattedMessage
                    id="product-list.greeting"
                    values={{
                      advisorName: Functions.displayAdvisorName(caRecommendation.advisor),
                      linebreak: <br />,
                      space: '\u00a0',
                    }}
                  />
                </Greeting>
              )}
              <AdvisorName>
                {language !== 'kr' && (Functions.displayAdvisorName(caRecommendation.advisor))}
              </AdvisorName>
              <SocialContactGroup isDesktopVersion={isDesktopVersion} />
            </StyledMessages>
          )}
      </IntroContainer>
    </ThemeProvider>
  );
};

export default Introduction;
