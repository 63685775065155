import styled, { css } from 'styled-components';
import { colorUsage, onLargeScreen, onSmallScreen } from '@stylesheet';
import theme from 'styled-theming';

export const IntroContainer = styled.div`
  ${onLargeScreen(css`
    min-height: 600px;
  `)}
  width: 100%;
  display: grid;
  box-sizing: border-box;
  overflow: hidden;
  font-family: "guccisansprolight", serif;
  ${onSmallScreen(css`
    grid-template-columns: auto;
  `)}
`;

export const StyledMessages = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${colorUsage.burgundy};
  color: ${colorUsage.white};
  padding: 100px 90px 100px 90px;
  font-size: 27pt;
  outline: 1px solid ${colorUsage.black};
  ${onSmallScreen(css`
    min-height: 300px;
    font-size: 23px;
    padding: 20px 16px 20px 16px;
  `)}
`;

export const Hello = styled.div`
  line-height: 1.5;
  font-style: italic;
  ${onLargeScreen(css`
    line-height: 1.7;
  `)}
  text-align: start;
  ${theme('language', {
    ar: css`
      direction: rtl;
    `,
  })}
`;

export const MySelection = styled.div`
  line-height: 1.5;
  ${onLargeScreen(css`
    line-height: 1.7;
  `)}
  ${theme('language', {
    ar: css`
      direction: rtl;
    `,
  })}
`;

export const Greeting = styled.div`
  line-height: 1.5;
  ${onLargeScreen(css`
    line-height: 1.7;
  `)}  
  ${theme('language', {
    ar: css`
      direction: rtl;
    `,
  })}
`;

export const AdvisorName = styled.div`
  line-height: 1.5;
  font-style: italic;
  ${onLargeScreen(css`
    line-height: 1.7;
  `)}  
  ${theme('language', {
    ar: css`
      direction: rtl;
    `,
  })}
`;
