import React from 'react';
import { RouteProps } from 'react-router-dom';
import { RouteContainer } from '@components/AnimatedRoute/AnimatedRoute.style';

export enum ANIMATIONS_DIRECTION {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  UP = 'UP',
  DOWN = 'DOWN',
  NONE = 'NONE',
}

interface Props {
  enterDirection?: ANIMATIONS_DIRECTION;
  exitDirection?: ANIMATIONS_DIRECTION;
}

const AnimatedRoute: React.FC<RouteProps & Props> = ({ children,
  enterDirection,
  exitDirection,
  ...routeProps
}) =>

/* todo find a way to replace history.action */
/* if (history.action === 'PUSH') {
    if (!enterDirection) enterDirection = ANIMATIONS_DIRECTION.RIGHT;
    if (!exitDirection) exitDirection = ANIMATIONS_DIRECTION.LEFT;
  } else {
    if (!enterDirection) enterDirection = ANIMATIONS_DIRECTION.LEFT;
    if (!exitDirection) exitDirection = ANIMATIONS_DIRECTION.RIGHT;
  } */

  // eslint-disable-next-line implicit-arrow-linebreak
  (
    <RouteContainer enterDirection={enterDirection} exitDirection={exitDirection}>
      {children}
    </RouteContainer>
  );
export default AnimatedRoute;
