import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CARecommendation } from './types';

export type CARecommendationState = Readonly<{
  caRecommendation: (CARecommendation & { recommendationId: string }) | null;
}>;

const initialState: CARecommendationState = {
  caRecommendation: null,
};

const caRecommendationSlice = createSlice({
  name: 'Recommendation',
  initialState,
  reducers: {
    updateCaRecommendation: (
      state,
      action: PayloadAction<CARecommendation & { recommendationId: string }>,
    ) => {
      state.caRecommendation = action.payload;
    },
    resetRecommendation: (state) => {
      state.caRecommendation = null;
    },
  },
});

export const { updateCaRecommendation, resetRecommendation } = caRecommendationSlice.actions;
export default caRecommendationSlice.reducer;
