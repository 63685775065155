import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getCaRecommendation } from '@redux/caRecommendation/selectors';
import gucciLogo from '@assets/bubble_icon.png';

import { PublicAdvisor } from '@redux/advisors/types';
import {
  AdvisorContainer,
  AdvisorImage,
  AdvisorImageMobile,
  ChevronIcon,
  ExpandIconContainer,
  PhoneNumber,
  SocialContactContainer,
  SocialContactContainerMobile,
  SocialContactContent,
  SocialContactContentMobile,
  SocialContactIconsContainer,
  SocialContactRow,
  StyledDesktopLineIcon,
  StyledDesktopMailIcon,
  StyledDesktopNumberIcon,
  StyledDesktopSmsIcon,
  StyledDesktopWhatsAppIcon,
  StyledLink,
  Text,
  TextMobile
} from './style';

interface Props {
  isDesktopVersion: boolean;
}

interface contactMethodsValuesType {
  name: keyof PublicAdvisor;
  icon: React.JSX.Element;
  linkPrefix?: string;
}

const contactMethodsValues: contactMethodsValuesType[] = [
  {
    name: 'workPhoneNumber',
    icon: <StyledDesktopNumberIcon />,
    linkPrefix: 'tel:',
  },
  {
    name: 'workPhoneNumber',
    icon: <StyledDesktopSmsIcon />,
    linkPrefix: 'sms:',
  },
  {
    name: 'email',
    icon: <StyledDesktopMailIcon />,
    linkPrefix: 'mailto:',
  },
  {
    name: 'whatsApp',
    icon: <StyledDesktopWhatsAppIcon />,
  },
  {
    name: 'line',
    icon: <StyledDesktopLineIcon />,
  },
];

const MAX_ICONS_TO_DISPLAY_MOBILE: number = 3;

function countContactMethods(advisor: PublicAdvisor): number {
  let count = 0;

  contactMethodsValues.forEach((method) => {
    if (advisor[method.name] !== undefined) {
      count += 1;
    }
  });

  return count;
}

const displaySocialContactLinks = (nbContactMethods: number, displayAll: boolean, advisor?: PublicAdvisor) => {
  let count = 0;

  return contactMethodsValues
    .filter((method) => advisor?.[method.name])
    .map((method) => {
      if (nbContactMethods <= MAX_ICONS_TO_DISPLAY_MOBILE || count < MAX_ICONS_TO_DISPLAY_MOBILE || displayAll) {
        const link = method.linkPrefix ? `${method.linkPrefix}${advisor?.[method.name]}` : advisor?.[method.name];
        count += 1;
        return (
          <StyledLink key={`icon_${method.name}_${count}`} href={link} rel="noopener noreferrer" target="_blank">
            {method.icon}
          </StyledLink>
        );
      }
      return null;
    });
};

type SocialContactGroupType = React.FunctionComponent<Props>;

const SocialContactGroup: SocialContactGroupType = ({ isDesktopVersion }: Props) => {
  const { advisor } = useSelector(getCaRecommendation) || {};

  const [displayAllIcons, setDisplayAllIcons] = useState(false);

  const nbContactMethods = advisor ? countContactMethods(advisor) : 0;

  return (
    <SocialContactRow>
      {isDesktopVersion ? (
        <SocialContactContainer containerWidth={nbContactMethods}>
          <SocialContactContent>
            <AdvisorContainer>
              {/* TODO replace img */}
              <AdvisorImage src={gucciLogo} alt="" />
              <Text>
                {advisor?.displayName}
              </Text>
            </AdvisorContainer>
            <SocialContactIconsContainer>
              {advisor?.workPhoneNumber && (
              <StyledLink href={`tel:${advisor?.workPhoneNumber}`} rel="noopener noreferrer" target="_blank">
                <StyledDesktopNumberIcon className="phone-icon" />
                <PhoneNumber className="phone-number">{advisor?.workPhoneNumber}</PhoneNumber>
              </StyledLink>
              )}
              {advisor?.workPhoneNumber && (
              <StyledLink href={`sms:${advisor?.workPhoneNumber}`} rel="noopener noreferrer" target="_blank">
                <StyledDesktopSmsIcon className="sms-icon" />
                <PhoneNumber className="phone-number">{advisor?.workPhoneNumber}</PhoneNumber>
              </StyledLink>
              )}
              {advisor?.email && (
              <StyledLink href={`mailto:${advisor?.email}`} rel="noopener noreferrer" target="_blank">
                <StyledDesktopMailIcon />
                <div>{advisor?.email}</div>
              </StyledLink>
              )}
              {advisor?.whatsApp && (
              <StyledLink href={advisor?.whatsApp} rel="noopener noreferrer" target="_blank">
                <StyledDesktopWhatsAppIcon />
                <PhoneNumber className="phone-number">{advisor?.workPhoneNumber}</PhoneNumber>
              </StyledLink>
              )}
              {advisor?.line && (
              <StyledLink href={advisor?.line} rel="noopener noreferrer" target="_blank">
                <StyledDesktopLineIcon />
                <div>{advisor?.linePseudo}</div>
              </StyledLink>
              )}
            </SocialContactIconsContainer>
          </SocialContactContent>
        </SocialContactContainer>
      )
        : (
          <SocialContactContainerMobile>
            <SocialContactContentMobile>
              <AdvisorImageMobile src={gucciLogo} alt="" />
              {!displayAllIcons && <TextMobile>{advisor?.displayName}</TextMobile>}
              <SocialContactIconsContainer>
                {displaySocialContactLinks(nbContactMethods, displayAllIcons, advisor)}
                <ExpandIconContainer onClick={() => setDisplayAllIcons(!displayAllIcons)}>
                  <ChevronIcon displayAllIcons={displayAllIcons} />
                </ExpandIconContainer>
              </SocialContactIconsContainer>
            </SocialContactContentMobile>
          </SocialContactContainerMobile>
        )}

    </SocialContactRow>
  );
};

export default SocialContactGroup;
