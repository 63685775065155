import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { bvConfiguration, defaultConfiguration, ggConfiguration } from '@constants/brandConfiguration';
import { BrandConfiguration } from '@redux/brandConfiguration/types';

export type BrandConfigurationState = Readonly<{
  brandConfiguration: BrandConfiguration;
}>;

const initialState: BrandConfigurationState = {
  brandConfiguration: defaultConfiguration,
};

const brandConfigurationSlice = createSlice({
  name: 'BrandConfiguration',
  initialState,
  reducers: {
    updateBrandConfiguration: (state, action: PayloadAction<string>) => {
      switch (action.payload) {
        case 'GG': {
          state.brandConfiguration = ggConfiguration;
          break;
        }
        case 'BV': {
          state.brandConfiguration = bvConfiguration;
          break;
        }
        default: {
          state.brandConfiguration = defaultConfiguration;
          break;
        }
      }
    },
    resetBrandConfiguration: (state) => {
      state.brandConfiguration = defaultConfiguration;
    },
  },
});

export const { updateBrandConfiguration, resetBrandConfiguration } = brandConfigurationSlice.actions;
export default brandConfigurationSlice.reducer;
