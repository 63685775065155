import { css } from 'styled-components';

/**
 * App spacing measurement convention
 * Use the getSpacing function below to compute padding and margin
 */
export const SPACING_UNIT = 8;
const MEASUREMENT_UNIT = 'px';

export const isLargeScreen = () => window.innerWidth > 1080;

/**
 * Do not use directly the colorPalette in your components
 * Create an entry in the colorUsage below instead
 */
const colorPalette = {
  greyLight: '#e0e0e0',
  greyDark: '#222',
  grey: '#666666',
  lightGrey: '#E7E7E7',
  lightYellow: '#FEE57A',
  amberLight: '#FFD54F',
  amber: '#FFC107',
  amberDark: '#FF8F00',
  yellow: '#ffc600',
  blueLight: '#F4F9FB',
  red: '#FF7373',
  blackTransparent: 'rgba(0, 0, 0, 0.24)',
  alabaster: '#F9F9F9',

  // Gucci colors
  darkestGreen: '#223627',
  darkGreen: '#001D2F',
  faluRed: '#900F28',
  lightBlack: '#1B1B1B',
  mediumGrey: '#9B9B9B',
  mediumLightGrey: '#BDBDBD',
  blue: '#1890FF',
  white: '#FFFFFF',
  black: '#000000',
  lighterGrey: '#F2F2F2',
  burgundy: '#530107',
  lightBurgundy: '#6A111B'
};

/**
 * Use this dictionnary in your components
 * Define a new key each time you use a colour if it's for a different use
 * Ex: fill, border-color, background-color, color ...
 */
export const colorUsage = {
  headerBackground: colorPalette.greyDark,
  primaryTextColor: colorPalette.greyDark,
  primaryLight: colorPalette.amberLight,
  primary: colorPalette.amber,
  primaryDark: colorPalette.amberDark,
  contentBackground: colorPalette.blueLight,
  linkColor: colorPalette.greyDark,
  linkColorHover: colorPalette.amberDark,
  linkColorDisabled: colorPalette.greyLight,
  codeColor: colorPalette.amberDark,
  primaryButtonColor: colorPalette.white,
  primaryButtonBackground: colorPalette.amberDark,
  primaryButtonBackgroundHover: colorPalette.amber,
  primaryButtonBackgroundDisabled: colorPalette.greyLight,
  loaderColorDefault: colorPalette.amberDark,
  error: colorPalette.red,
  inputBackground: colorPalette.white,
  inputBorderColor: colorPalette.blackTransparent,
  inputPlaceholderColor: colorPalette.blackTransparent,
  backgroundIcon: colorPalette.grey,
  boxBorderColor: colorPalette.lightGrey,
  noteText: colorPalette.mediumLightGrey,
  selectedBorder: colorPalette.mediumLightGrey,
  background2: colorPalette.lightYellow,
  contactGreetingsBackground: colorPalette.lightBurgundy,

  // Gucci colors
  accent: colorPalette.darkestGreen,
  accent2: colorPalette.darkGreen,
  accent3: colorPalette.faluRed,
  background: colorPalette.lightGrey,
  text: colorPalette.lightBlack,
  textGrey: colorPalette.mediumGrey,
  hover: colorPalette.blue,
  white: colorPalette.white,
  black: colorPalette.black,
  yellow: colorPalette.yellow,
  burgundy: colorPalette.burgundy
};

export const fontFamily = {
  main: '\'guccisans\', \'Helvetica\', \'Arial\', sans-serif',
  code: 'Monospace',
};

export const fontSize = {
  XXXLarge: '60px',
  XXLarge: '38px',
  XLarge: '32px',
  large: '24px',
  mediumLarge: '20px',
  medium: '16px',
  small: '14px',
  XSmall: '12px',
  XXSmall: '10px',
  XXXSmall: '8px',
};

export const fontWeight = {
  bold: '700',
  medium: '500',
  normal: '400',
  light: '300',
};

export const lineHeight = {
  XXXLarge: '72px',
  XXLarge: '46px',
  large: '32px',
  mediumLarge: '28px',
  medium: '24px',
  small: '16px',
  smaller: '14px',
  XSmall: '12px',
};

export const borderRadius = {
  medium: '4px',
  large: '10px',
};

export const font = {
  XXXLarge: css`
    font-family: ${fontFamily.main};
    font-size: ${fontSize.XXXLarge};
    line-height: ${lineHeight.XXXLarge};
  `,
  XXLarge: css`
    font-family: ${fontFamily.main};
    font-size: ${fontSize.XXLarge};
    line-height: ${lineHeight.XXLarge};
  `,
  XLarge: css`
    font-family: ${fontFamily.main};
    font-size: ${fontSize.XLarge};
    line-height: ${lineHeight.large};
  `,
  Large: css`
    font-family: ${fontFamily.main};
    font-size: ${fontSize.large};
    line-height: ${lineHeight.large};
  `,
  medium: css`
    font-family: ${fontFamily.main};
    font-size: ${fontSize.medium};
    line-height: ${lineHeight.medium};
  `,
  Small: css`
    font-family: ${fontFamily.main};
    font-size: ${fontSize.small};
    line-height: ${lineHeight.small};
  `,
  XXSmall: css`
    font-family: ${fontFamily.main};
    font-size: ${fontSize.XXSmall};
    line-height: ${lineHeight.small};
  `,
};

export const onLandscape = (args: any) => css`
  @media (orientation: landscape) {
    ${args}
  }
`;

export const onPortrait = (args: any) => css`
  @media (orientation: portrait) {
    ${args}
  }
`;

export const LargeScreenLimit = 1024;

export const onLargeScreen = (args: any) => css`
  @media (min-width: ${LargeScreenLimit}px) {
    ${args}
  }
`;

export const onSmallScreen = (args: any) => css`
  @media (max-width: 1023px) {
    ${args}
  }
`;

export const getSpacing = (multiplier: number): string => `${multiplier * SPACING_UNIT}${MEASUREMENT_UNIT}`;

export const greyBackgroundOnExit = css`
  background-color: ${colorUsage.white};

  &.animation-exit * {
    opacity: 1;
  }

  &.animation-exit-active * {
    animationacity: 600ms;
    opacity: 0.7;
  }
`;
