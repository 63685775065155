import styled, { css } from 'styled-components';
import theme from 'styled-theming';

import { colorUsage, getSpacing, onLargeScreen, onSmallScreen } from '@stylesheet';

export const Container = styled.div<{ height?: number}>`
  width: 100%;
  height: ${(props) => props.height}px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${colorUsage.white};
  overflow: hidden;
  &::-webkit-scrollbar {
    display: none;
  }

  &.introduction-exit {
    transform: translateY(0);
  }
  &.introduction-exit-active {
    transform: translateY(-100%);
    transition: transform 600ms;
  }
  
  background-color: ${colorUsage.burgundy};

  ${onSmallScreen(css`
    align-items: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 9vh ${getSpacing(4)} ${getSpacing(20)};
  `)}
`;

export const TextContainer = styled.div`
  margin: 50px 0 30px 0;
  text-align: center;
  width: 100%;
  font-family: "guccisansprolight", serif;
  ${onLargeScreen(css`
    min-height: 600px;
  `)}
`;

export const HelloText = styled.div`
  font-size: 17pt;
  color: ${colorUsage.white};
  font-style: italic;
  ${theme('language', {
    ar: css`
      text-align: right;
      direction: rtl;
    `,
  })}
`;

export const DiscoverText = styled.div`
  margin-top: ${getSpacing(2)};
  font-size: 17pt;
  line-height: 40px;
  color: ${colorUsage.white};
  ${theme('language', {
    ar: css`
      text-align: right;
      direction: rtl;
    `,
  })}
`;

export const AdvisorText = styled.div`
  font-size: 17pt;
  color: ${colorUsage.white};
  font-style: italic;
  ${theme('language', {
    ar: css`
      text-align: right;
      direction: rtl;
    `,
  })}
`;
