import styled, { css } from 'styled-components';
import { colorUsage, getSpacing, onSmallScreen } from '@stylesheet';
import { ReactComponent as Logo } from '@assets/gucci_logo.svg';

const BlackGucciHeaderLogo = styled(Logo)`
  min-height: ${getSpacing(3)};
  width: ${getSpacing(20)};
  fill: ${colorUsage.black};
  padding-left: 70px;
  ${onSmallScreen(css`
    width: ${getSpacing(15)};
    padding-left: 20px;
  `)}
`;

export default BlackGucciHeaderLogo;
