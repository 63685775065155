import styled, { css } from 'styled-components';
import { greyBackgroundOnExit } from '@stylesheet';
import { ANIMATIONS_DIRECTION } from '@components/AnimatedRoute/AnimatedRoute';

export const RouteContainer = styled.div<{
  enterDirection?: ANIMATIONS_DIRECTION;
  exitDirection?: ANIMATIONS_DIRECTION;
}>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  ${greyBackgroundOnExit};

  &.animation-enter {
    ${(props) => props.enterDirection && getTransformFromDirection(props.enterDirection)}
  }

  &.animation-enter-active {
    z-index: 1;
    transform: translateX(0);
    transition: transform 600ms;
  }

  &.animation-exit {
    transform: translateX(0);
  }

  &.animation-exit-active {
    z-index: 0;
    ${(props) => props.exitDirection && getTransformFromDirection(props.exitDirection)}
    transition: transform 1000ms;
  }
`;

// eslint-disable-next-line consistent-return
const getTransformFromDirection = (direction: ANIMATIONS_DIRECTION) => {
  // eslint-disable-next-line default-case
  switch (direction) {
    case ANIMATIONS_DIRECTION.LEFT:
      return css`
        transform: translateX(-100%);
      `;
    case ANIMATIONS_DIRECTION.RIGHT:
      return css`
        transform: translateX(100%);
      `;
    case ANIMATIONS_DIRECTION.UP:
      return css`
        transform: translateY(-100%);
      `;
    case ANIMATIONS_DIRECTION.DOWN:
      return css`
        transform: translateY(100%);
      `;
  }
};
