import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCaRecommendation } from '@redux/caRecommendation/selectors';
import { useFetchCaRecommendation } from '@redux/caRecommendation/hooks';
import Loader from '@components/Loader';
import { useParams } from 'react-router-dom';
import { Container, Content, } from '@pages/ProductDetailPage/ProductDetailPage.style';
import { DesktopNavBar, MobileNavBar } from '@components/NavBar';
import { Footer } from '@components/Footer';
import { LookbookProduct, Product, RECOMMENDATION_TYPE } from '@redux/caRecommendation/types';
import ContactModal from '@components/ContactModal';
import ContactBubble from '@components/ContactBubble';
import MobileProductDetailPage from '@/pages/ProductDetailPage/components/MobileProductDetailPage';
import DesktopProductDetailPage from '@/pages/ProductDetailPage/components/DesktopProductDetailPage';

type IProductDetailPage = {
  recommendationId: string;
  productStyleColorCode: string;
}

const ProductDetailPage: React.FunctionComponent = () => {
  const { recommendationId, productStyleColorCode } = useParams<IProductDetailPage>();

  const [openContactModal, setOpenContactModal] = useState(false);

  const [, fetchCaRecommendation] = useFetchCaRecommendation();

  const recommendation = useSelector(getCaRecommendation);

  useEffect(() => {
    (async function fetch() {
      if (!recommendation && recommendationId) {
        await fetchCaRecommendation(recommendationId);
      }
    }());
  }, [recommendation, recommendationId, fetchCaRecommendation]);

  if (!recommendationId || !recommendation || !productStyleColorCode) {
    return <Loader />;
  }

  let selectedProduct: Product | undefined;
  if (recommendation?.recommendationType === RECOMMENDATION_TYPE.CURATED_LIST) {
    const products = recommendation?.products as Product[];
    selectedProduct = products.find((product) => product.styleColorCode === productStyleColorCode);
  }
  if (recommendation?.recommendationType === RECOMMENDATION_TYPE.LOOKBOOK) {
    const products = recommendation?.products as LookbookProduct[];
    products.forEach((product) => {
      if (selectedProduct === undefined) {
        const findedProduct = product.products.find((p) => p.styleColorCode === productStyleColorCode);
        if (findedProduct) {
          selectedProduct = findedProduct;
        }
      }
    });
  }

  return (
    <>
      {selectedProduct
        ? (
          <Container>
            <DesktopNavBar caRecommendationId={recommendationId} />
            <MobileNavBar caRecommendationId={recommendationId} />
            <Content>
              <MobileProductDetailPage product={selectedProduct} caRecommendationId={recommendationId} />
              <DesktopProductDetailPage product={selectedProduct} caRecommendationId={recommendationId} />
            </Content>
            {/* TODO link SA picture */}
            <ContactBubble action={() => setOpenContactModal(!openContactModal)} isOpen={openContactModal} image="" />
            <Footer />
            {recommendation?.advisor
            && <ContactModal open={openContactModal} advisor={recommendation.advisor} />}
          </Container>
        )
        : <Loader />}
    </>
  );
};

export default ProductDetailPage;
