import React from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';
import {
  DesktopContainer,
  HeaderFavoriteContainer,
  MobileContainer,
  StyledLink,
  StyledMyFavorites,
  StyledNavBar, StyledText,
} from '@components/NavBar/style';
import { PATHS } from '@routes';
import WhiteHeart from '@assets/like.svg';
import { useSelector } from 'react-redux';
import { getCaRecommendation } from '@redux/caRecommendation/selectors';
import Functions from '@utils/Functions';
import BlackGucciHeaderLogo from '@components/BlackGucciHeaderLogo';
import HeaderLinksGroup from '@components/HeaderLinksGroup';

interface Props {
  caRecommendationId?: string;
  isProductDetailPage?: boolean;
}

export const DesktopNavBar: React.FunctionComponent<Props> = ({
  caRecommendationId,
}) => {
  const navigate = useNavigate();

  return (
    <DesktopContainer>
      <StyledNavBar isDesktopVersion>
        <BlackGucciHeaderLogo />
        <HeaderLinksGroup isMobileVersion={false}>
          <HeaderFavoriteContainer isDesktopVersion>
            <StyledLink onClick={() => navigate(generatePath(PATHS.LIKED_PRODUCTS, { caRecommendationId }))}>
              <StyledText>
                <FormattedMessage id="nav-bar.favorites" />
              </StyledText>
              <StyledMyFavorites src={WhiteHeart} alt="My Favorites" />
            </StyledLink>
          </HeaderFavoriteContainer>
        </HeaderLinksGroup>

      </StyledNavBar>
    </DesktopContainer>
  );
};

export const MobileNavBar: React.FunctionComponent<Props> = ({
  caRecommendationId,
}) => {
  const navigate = useNavigate();
  const { advisor } = useSelector(getCaRecommendation) || {};
  const contactMethodNumber = Functions.countContactMethodNumber(advisor);
  const isMobileDevice = Functions.isMobileDevice();

  return (
    <MobileContainer>
      <StyledNavBar contactMethodNumber={contactMethodNumber} isDesktopVersion={false}>
        <BlackGucciHeaderLogo />
        <HeaderLinksGroup isMobileVersion={isMobileDevice}>
          <HeaderFavoriteContainer isDesktopVersion={false}>
            <StyledLink onClick={() => navigate(generatePath(PATHS.LIKED_PRODUCTS, { caRecommendationId }))}>
              <StyledMyFavorites src={WhiteHeart} alt="My Favorites" />
            </StyledLink>
          </HeaderFavoriteContainer>
        </HeaderLinksGroup>
      </StyledNavBar>
    </MobileContainer>
  );
};
