import styled from 'styled-components';

export const StyledCarousel = styled.div<{ width?: number }>`
  .dots-button {
    visibility: hidden;
  }

  .slick-next {
    right: 10px;
    z-index: 1;
    scale: 2;
  }

  .slick-prev {
    left: 10px;
    z-index: 1;
    scale: 2;
  }
`;
