/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.ts, reducers wouldn't be hot reloadable.
 */

import { combineReducers } from 'redux';
import { RootState } from './types';
import { reducer as caRecommendationReducer } from './caRecommendation';
import { reducer as brandConfigurationReducer } from './brandConfiguration';
import { reducer as languageReducer } from './language';

export default combineReducers<RootState>({
  caRecommendation: caRecommendationReducer,
  brandConfiguration: brandConfigurationReducer,
  language: languageReducer
});
