import { useAsyncFn } from 'react-use';
import { useDispatch } from 'react-redux';
import { updateBrandConfiguration } from '@redux/brandConfiguration/slice';
import { updateLanguage } from '@redux/language/slice';
import api from '@services/networking/api';
import { updateCaRecommendation } from '@redux/caRecommendation/slice';

export const useActivateCaRecommendation = () => {
  const [, fetchCaRecommendation] = useFetchCaRecommendation();
  return useAsyncFn(async (linkId: string, isAdvisor: string) => {
    const { data: caRecommendation } = await api.getActivateCARecommendation(linkId, isAdvisor);
    await fetchCaRecommendation(caRecommendation.id);
  });
};

export const useFetchCaRecommendation = () => {
  const dispatch = useDispatch();
  return useAsyncFn(async (id: string) => {
    const { data: caRecommendation } = await api.getCARecommendation(id);
    dispatch(updateCaRecommendation({ ...caRecommendation, recommendationId: id }));
    dispatch(updateBrandConfiguration(caRecommendation.brand));
    dispatch(updateLanguage(caRecommendation.lang));
  });
};

export const useUpdateFeedback = () => {
  const [, fetchCaRecommendation] = useFetchCaRecommendation();
  return useAsyncFn(
    async (recommendationId: string, productStyleColorCode: string, feedback: number | null) => {
      const { data: caRecommendation } = await api.updateFeedback(feedback, recommendationId, productStyleColorCode);
      await fetchCaRecommendation(caRecommendation.id);
    },
  );
};
