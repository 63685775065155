import { PublicAdvisor } from '@redux/advisors/types';
import { Currency } from '@constants/currency';

export interface Sku {
  sku: number;
  size: string;
  locationCode: number | null;
  stockAvailable: number | null;
}

export interface Product {
  id: string;
  styleColorCode: string;
  styleCode?: string;
  selectedSize: string;
  feedback: number | null;
  skus: Sku[];
  name: string;
  description: string;
  material: string;
  category: string;
  url: string;
  externalMediaUrls: string[];
  thumbnail: string;
  priceAmount: number;
  priceCurrency: Currency;
}

export interface CARecommendation {
  id: string;
  algoRecoId: string;
  products: Product[] | LookbookProduct[];
  validated: boolean;
  advisor: PublicAdvisor;
  clientLocalFirstName?: string;
  clientLocalMiddleName?: string;
  clientDisplayName?: string;
  clientGender?: string;
  clientTitle?: string;
  link: {
    value: string;
    expiresOn: Date | null;
  };
  createdAt: Date;
  updatedAt: Date;
  brand?: string;
  lang: string;
  greetings?: Greetings
  recommendationType: RECOMMENDATION_TYPE;
}

export interface Greetings {
  salutation: string;
  body: string;
  signature: string;
}

export interface LookbookProduct {
  chapterName: string,
  products: Product[]
}

export enum RECOMMENDATION_TYPE {
  LOOKBOOK = 'LOOKBOOK',
  CURATED_LIST = 'CURATED LIST'
}
