import React from 'react';

import gucciLogo from '@assets/bubble_icon.png';

import { ContactButton, ContactImage, StyledArrowDownIcon, StyledBubbleContainer } from './style';

type Props = {
  action: () => void
  isOpen: boolean
  image: string
}

const ContactBubble: React.FC<Props> = ({ action, isOpen, image }) => (
  <ContactButton onClick={action}>
    <StyledBubbleContainer />
    {/* TODO replace image */}
    {!isOpen
      ? <ContactImage src={gucciLogo} alt="" />
      : <StyledArrowDownIcon />}
  </ContactButton>
);

export default ContactBubble;
