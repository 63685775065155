import styled, { css } from 'styled-components';
import { colorUsage, getSpacing, onSmallScreen } from '@stylesheet';
import { ReactComponent as DesktopWhatsAppIcon } from '@assets/whatApp/whatsapp.svg';
import { ReactComponent as DesktopNumberIcon } from '@assets/number/phone.svg';
import { ReactComponent as DesktopMailIcon } from '@assets/mail/mail.svg';
import { ReactComponent as DesktopSmsIcon } from '@assets/sms/sms.svg';
import { ReactComponent as DesktopLineIcon } from '@assets/line/line.svg';
import { ReactComponent as DesktopKakaoTalkIcon } from '@assets/kakaoTalk/kakaotalk.svg';
import { ReactComponent as Logo } from '@assets/gucci_logo.svg';
import theme from 'styled-theming';

export const BlackGucciLogo = styled(Logo)`
  min-height: ${getSpacing(4)};
  width: ${getSpacing(25)};
  fill: ${colorUsage.black};
`;

export const ModalContainer = styled.div`
  position: fixed;
  bottom: ${getSpacing(13)};
  right: ${getSpacing(4)};
  min-width: 300px;
  height: auto;
  background: white;
  border-radius: 40px;
  z-index: 1000;
  opacity: 1;
  padding: ${getSpacing(3)};
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.05);
  ${onSmallScreen(css`
    min-width: 0;
    width: 80%;
    right: 4.5%;
    bottom: ${getSpacing(11)};
  `)}
`;

export const ModalContent = styled.div`
  width: 100%;
  margin-top: ${getSpacing(2)};
  margin-bottom:  ${getSpacing(2)};
`;

export const AdvisorContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AdvisorInformations = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const AdvisorName = styled.h1`
  font-size: 18px;
`;

export const AdvisorImageContainer = styled.div`
  margin: auto;
  justify-content: center;
  display: flex;
  width: 48px;
  height: 48px;
  padding-bottom: ${getSpacing(1.5)};
`;

export const AdvisorImage = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 48px;
  object-fit: cover;
`;

export const AdvisorFunction = styled.h2`
  padding-top: ${getSpacing(0.5)};
  font-size: 14px;
  justify-content: center;
  color: ${colorUsage.textGrey};
`;

export const AdvisorContactsContainer = styled.div`
  margin-top: ${getSpacing(2)};
  display: flex;
  flex-direction: column;
`;

export const AdvisorContact = styled.div`
  display: flex;
  margin-top:  ${getSpacing(1)};
  margin-bottom:  ${getSpacing(1)};
  ${theme('language', {
    ar: css`
      justify-content: end;
      flex-direction: row-reverse;
    `,
  })}
`;

export const ContactIcon = styled.div`
  display: flex;
  width: 30px;
  height: 30px;
`;

export const AdvisorContactText = styled.p`
  margin-top: auto; 
  margin-bottom: auto; 
  margin-left: ${getSpacing(1)};
  color: ${colorUsage.textGrey};
`;

export const AdvisorContactLink = styled.a`
  &:hover {
    cursor: pointer;
  }
  color: ${colorUsage.textGrey};
`;

export const StyledDesktopNumberIcon = styled(DesktopNumberIcon)`
  margin: auto;
  stroke: ${colorUsage.textGrey};
`;

export const StyledDesktopWhatsAppIcon = styled(DesktopWhatsAppIcon)`
  margin: auto;
  fill: ${colorUsage.textGrey};
`;

export const StyledDesktopMailIcon = styled(DesktopMailIcon)`
  margin: auto;
  stroke: ${colorUsage.textGrey};
`;

export const StyledDesktopSmsIcon = styled(DesktopSmsIcon)`
  margin: auto;
  fill: ${colorUsage.textGrey};
`;

export const StyledDesktopLineIcon = styled(DesktopLineIcon)`
  margin: auto;
`;

export const StyledDesktopKakaoTalkIcon = styled(DesktopKakaoTalkIcon)`
  margin: auto;
`;
