import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import Button from '@components/Button';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { getCaRecommendation } from '@redux/caRecommendation/selectors';
import Functions from '@utils/Functions';
import { ThemeProvider } from 'styled-components/macro';
import { getLanguage } from '@redux/language/selectors';
import {
  AdvisorText,
  Container,
  DiscoverText,
  HelloText,
  TextContainer,
} from '@pages/Suggestion/components/Introduction/Introduction.style';
import StyledLogo from '@components/StyledLogo';

interface Props {
  goToSelection: () => void;
}

const Introduction: React.FC<Props> = ({ goToSelection }) => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);
  const caRecommendation = useSelector(getCaRecommendation);
  const customer = caRecommendation?.clientDisplayName || caRecommendation?.clientLocalFirstName;
  const customerGenderTranslation = caRecommendation?.clientGender === 'MALE' ? Functions.getGenderTranslation('MR') : Functions.getGenderTranslation(caRecommendation?.clientTitle);
  const language = useSelector(getLanguage)?.language.locale;
  const isMySelectionGenderSpecific = language === 'ar';

  return (
    <ThemeProvider theme={{ language }}>
      <Container height={window.innerHeight} theme={brandConfiguration}>
        <StyledLogo />
        {caRecommendation?.greetings
          ? (
            <TextContainer>
              <HelloText>{caRecommendation.greetings.salutation}</HelloText>
              <DiscoverText>{caRecommendation.greetings.body}</DiscoverText>
              <DiscoverText>
                {caRecommendation.greetings.signature}
                <br />
                {Functions.displayAdvisorName(caRecommendation.advisor)}
              </DiscoverText>
            </TextContainer>
          )
          : (
            <TextContainer>
              <HelloText>
                {customerGenderTranslation && language !== 'kr' && language !== 'jp' && (
                  <FormattedMessage
                    id={customerGenderTranslation}
                    values={{
                      space: '\u00a0',
                    }}
                  />
                )}
                {customer && (
                  <FormattedMessage
                    id="product-list.hello"
                    values={{
                      clientFirstName: language === 'kr' ? Functions.toTitleCase(caRecommendation?.clientLocalFirstName) : Functions.toTitleCase(customer),
                      clientLastName: Functions.toTitleCase(caRecommendation?.clientDisplayName),
                      clientMiddleName: `${caRecommendation?.clientLocalMiddleName && caRecommendation?.clientLocalFirstName ? ' ' : ''}${Functions.toTitleCase(caRecommendation?.clientLocalMiddleName)}`,
                      linebreak: <br />,
                      space: '\u00a0',
                    }}
                  />
                )}
              </HelloText>
              <DiscoverText>
                {isMySelectionGenderSpecific
                  ? (
                    <FormattedMessage
                      id={(caRecommendation?.clientGender !== undefined && caRecommendation?.clientGender !== 'MALE') ? 'product-list.my-selection.female' : 'product-list.my-selection.male'}
                      values={{
                        linebreak: <br />,
                        space: '\u00a0',
                      }}
                    />
                  ) : <FormattedMessage id="product-list.my-selection" values={{ linebreak: <br /> }} />}
              </DiscoverText>
              {language !== 'jp' && (
                <DiscoverText>
                  <FormattedMessage
                    id="product-list.greeting"
                    values={{
                      advisorName: Functions.displayAdvisorName(caRecommendation?.advisor),
                      linebreak: <br />,
                      space: '\u00a0',
                    }}
                  />
                </DiscoverText>
              )}
              <AdvisorText>
                {language !== 'kr' && (Functions.displayAdvisorName(caRecommendation?.advisor))}
              </AdvisorText>
            </TextContainer>
          )}
        <Button onClick={() => goToSelection()}>
          <FormattedMessage id="introduction.see-my-selection" />
        </Button>
      </Container>
    </ThemeProvider>
  );
};

export default Introduction;
